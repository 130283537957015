import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MrrService from "../services/mrr.service";
import AuthService from "../services/auth.service";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import UploadButton from "./upload";
import SendIcon from "@mui/icons-material/Send";
import PreviewIcon from "@mui/icons-material/Preview";
import ImageIcon from "@mui/icons-material/Image";

const MatSent = ({ data, callparentfun }) => {
  const handleEditClick = (e, data) => {
    e.stopPropagation();
    if (data.DocsPath === null) {
      alert("Please upload supportive document for this MRR.");
      return false;
    }

    if(data.itemcount === 0){
      alert("Please add item for this MRR.");
      return false;
    }
    if (
      window.confirm("Are you sure want to submit this MRR for approval?") ===
      false
    ) {
      return false;
    }

    MrrService.sentApproval(data.id).then(
      (response) => {
        if (response.status === 200) {
          callparentfun();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data.SentOnApproval ? (
        <IconButton
          color="secondary"
          aria-label="Sent Approval"
          onClick={(e) => handleEditClick(e, data)}
        >
          <SendIcon />
        </IconButton>
      ) : (
        <IconButton color="secondary" aria-label="Sent Approval">
          <SendIcon />
        </IconButton>
      )}
    </div>
  );
};

const MatEdit = ({ data, navigate }) => {
  const handleEditClick = (e, data) => {
    e.stopPropagation();
    navigate("/storeoperation", { state: data });
  };

  return (
    <FormControlLabel
      control={
        data.SentOnApproval ? (
          <IconButton
            color="secondary"
            aria-label="Sent Approval"
            onClick={(e) => handleEditClick(e, data)}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton color="secondary" aria-label="Sent Approval">
            <EditIcon />
          </IconButton>
        )
      }
    />
  );
};

export default function StoreMRRList({ DocType, DocRI, index }) {
  const [Storedata, setStoredata] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const strnm = AuthService.getStoreName();
  const token = AuthService.getToken();
  const isMRR = DocType === "MRR";
  const isREJ = DocType === "REJ";

  const getStorelistData = () => {
    setLoading(true);
    const UserId = AuthService.getCurrentUser();
    const Str_id = AuthService.getStoreId();
    MrrService.getStoreMRRList(UserId, Str_id, DocType, DocRI).then(
      (response) => {
        if (response.data.status) {
          setStoredata(response.data.data);
        }
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(_content);
        setLoading(false);
      }
    );
  };
  React.useEffect(() => {
    getStorelistData();
  }, []);

  const navigate = useNavigate();

  const handleRowDoubleClick = (params) => {
    //localStorage.setItem("Str_Id", params.row.Str_Id);
    if (params.row.SentOnApproval === 0 || params.row.IsApproved === 1) {
      alert("Already sent for approval or approved");
      return false;
    }
    // Navigate to the DetailsPage with the ID of the clicked row
    navigate("/detailsitem", {
      state: {
        id: params.row.id,
        DocType: params.row.DocType,
        DocNo: params.row.DocNo,
        DocDate: params.row.DocDate,
      },
    });
    //window.location.href = "/storeoperation";
  };
  const handleButtonClick = (params) => {
    //localStorage.setItem("Str_Id", params.row.Str_Id);
    // Navigate to the DetailsPage with the ID of the clicked row
    navigate("/storeoperation", { replace: true, state: { index: index } });
    //window.location.href = "/storeoperation";
  };

  const columns1 = [
    {
      field: "DocNo",
      headerName: "Doc No",
      width: 80,
    },
    {
      field: "DocDate",
      headerName: DocType + " Date",
      width: 100,
    },
    {
      field: "Name",
      headerName: "Vendor Name",
      width: 250,
    },
    {
      field: "LRNo",
      headerName: "LR No",
      width: 200,
    },
    {
      field: "Chln_Inv_No",
      headerName: "Challan No",
      sortable: false,
      width: 160,
    },
    {
      field: "Chln_Inv_Date",
      headerName: "Challan Date",
      sortable: false,
      width: 100,
    },
    {
      field: "Status",
      headerName: "Approved",
      sortable: false,
      width: 100,
    },
    {
      field: "Edit",
      headerName: "Edit",
      sortable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatEdit data={params.row} navigate={navigate} />
          </div>
        );
      },
    },
    {
      field: "itemcount",
      headerName: "Items",
      sortable: false,
      width: 50,
    },
    {
      field: "upload",
      headerName: "Upload",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (params.row.DocsPath || params.row.SentOnApproval === 0)  && params.row.IsApproved != 2 ? (
          <Link
            target="_blank"
            to={
              `/viewfile/${params.row.id}/?token=` + btoa(token) + "&doctype=u"
            }
          >
            <IconButton color="secondary" aria-label="View File">
              <ImageIcon></ImageIcon>
            </IconButton>
          </Link>
        ) : (
          <UploadButton
            Str_id={params.row.Str_id}
            id={params.row.id}
            callparentfun={getStorelistData}
          />
        );
      },
    },
    {
      field: "View",
      headerName: "View",
      sortable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <Link
            target="_blank"
            to={
              `/viewfile/${params.row.id}/?token=` + btoa(token) + "&doctype=d"
            }
          >
            <IconButton color="secondary" aria-label="View File">
              <PreviewIcon></PreviewIcon>
            </IconButton>
          </Link>
        );
      },
    },
    {
      field: "Sent",
      headerName: "Sent",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatSent data={params.row} callparentfun={getStorelistData} />
          </div>
        );
      },
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return (
          <>
            {params.row.ApprovalRemarks ? (
              <div>
                {" "}
                {params.row.ApprovalRemarks}
                {", " + params.row.Remarks}
              </div>
            ) : (
              <div>{params.row.Remarks} </div>
            )}
          </>
        );
      },
    },
  ];
  const columns2 = [
    {
      field: "DocNo",
      headerName: "Doc No",
      width: 80,
    },
    {
      field: "DocDate",
      headerName: DocType + " Date",
      width: 100,
    },
    {
      field: "Name",
      headerName: "Contractor Name",
      width: 250,
    },
    {
      field: "Mode",
      headerName: "Mode",
      width: 80,
    },
    {
      field: "NoOfPacks",
      headerName: "No. Of Package",
      width: 100,
    },
    {
      field: "IndentRef",
      headerName: "Indent Reference",
      width: 200,
    },
    {
      field: "Status",
      headerName: "Approved",
      sortable: false,
      width: 100,
    },
    {
      field: "Edit",
      headerName: "Edit",
      sortable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatEdit data={params.row} navigate={navigate} />
          </div>
        );
      },
    },
    {
      field: "itemcount",
      headerName: "Items",
      sortable: false,
      width: 50,
    },
    {
      field: "upload",
      headerName: "Upload",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (params.row.DocsPath || params.row.SentOnApproval === 0)  && params.row.IsApproved != 2 ? (
          <Link
            target="_blank"
            to={
              `/viewfile/${params.row.id}/?token=` + btoa(token) + "&doctype=u"
            }
          >
            <IconButton color="secondary" aria-label="View File">
              <ImageIcon></ImageIcon>{" "}
            </IconButton>
          </Link>
        ) : (
          <UploadButton
            Str_id={params.row.Str_id}
            id={params.row.id}
            callparentfun={getStorelistData}
          />
        );
      },
    },
    {
      field: "View",
      headerName: "View",
      sortable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <Link
            target="_blank"
            to={
              `/viewfile/${params.row.id}/?token=` + btoa(token) + "&doctype=d"
            }
          >
            <IconButton color="secondary" aria-label="View File">
              <PreviewIcon></PreviewIcon>{" "}
            </IconButton>
          </Link>
        );
      },
    },
    {
      field: "Sent",
      headerName: "Sent",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatSent data={params.row} callparentfun={getStorelistData} />
          </div>
        );
        //return <IssuePrint data={params} callparentfun={getStorelistData} />;
      },
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return (
          <>
            {params.row.ApprovalRemarks ? (
              <div>
                {" "}
                {params.row.ApprovalRemarks}
                {", " + params.row.Remarks}
              </div>
            ) : (
              <div>{params.row.Remarks} </div>
            )}
          </>
        );
      },
    },
  ];
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <Button
              type="submit"
              variant="contained"
              sx={{ mb: 2 }}
              onClick={handleButtonClick}
            >
              Create {DocType}
            </Button>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            id="transition-modal-title"
            variant="body1"
            sx={{ textAlign: "right" }}
          >
            Store Name : {strnm}
          </Typography>
        </Grid>
      </Grid>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DataGrid
        sx={{
          height: 500,
          width: "100%",
          boxShadow: 2,
          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        //getRowHeight={getRowHeight}
        getRowId={(Storedata) => Storedata.id}
        rows={Storedata}
        columns={isMRR || isREJ ? columns1 : columns2}
        pageSize={100}
        rowHeight={25}
        density="standard"
        rowsPerPageOptions={[100]}
        onRowDoubleClick={handleRowDoubleClick}
      />
    </Box>
  );
}
